exports.URShift = function URShift(number, bits) {
    if (number >= 0)
        return number >> bits;
    else
        return (number >> bits) + (2 << ~bits);
}
var ObjDP = Object.defineProperty;
function addAttr(obj, prop, value) {
    ObjDP(obj, prop, { configurable: true, value: value })
}

var Matrix = {
    getPoint: function (x, y) {
        return this[this.width * y + x];
    },
    setPoint: function (x, y, v) {
        return this[this.width * y + x] = v;
    },
    getRow: function (y) {
        var start = this.width * y
        return this.slice(start, start + this.width)
    },
    getColumn: function (x) {
        var col = [];
        var y = 0;
        while (y + x < this.length) {
            col.push(this[y + x])
            y += this.width;
        }
        return col;
    },
    getHeight: function () {
        return Math.ceil(this.length / width);
    }
}


exports.extendArray2Matrix = function (arr, width) {
    addAttr(arr, 'width', width);
    ObjDP(arr, 'height', { get: Matrix.getHeight });
    addAttr(arr, 'get', Matrix.getPoint);
    addAttr(arr, 'set', Matrix.setPoint);
    addAttr(arr, 'getRow', Matrix.getRow);
    addAttr(arr, 'getColumn', Matrix.getColumn);
    return arr;
}

/**
 * imageData对像转灰度矩阵
 * @param {object} imageData 
 */
exports.imageData2greyMatrix = function (imageData) {
    var data = imageData.data,
        width = imageData.width,
        height = imageData.height;
    var greyscalePixels = [];
    for (let x = 0; x < width; x++) {
        for (let y = 0; y < height; y++) {
            let p = (y * width + x) * 4;
            let r = data[p + 0];
            let g = data[p + 1];
            let b = data[p + 2];
            let a = data[p + 3];
            greyscalePixels.push((r * 0.2126 + g * 0.7152 + b * 0.0722) * a / 255);
        }
        extendArray2Matrix(greyscalePixels)
        return greyscalePixels;
    }
}

/** 点阵 */
var BitMatrix = function (data, width) {
    this.data = data;
    this.width = data.width || width;
}
BitMatrix.createEmpty = function (x, y) {
    // var obj = {
    //     data : new Array(x * y),
    //     width : y
    // }
    // obj.__proto__ = BitMatrix.prototype;
    return new BitMatrix(new Array(x * y), x);
}
BitMatrix.prototype = {
    'get': function (x, y) {
        return !!this.data[y * this.width + x];
    },
    'set': function (x, y, v) {
        this.data[y * this.width + x] = v ? true : false;
    },
    get height() {
        return Math.ceil(this.data.length / this.height)
    },
    getInverted() {
        return new BitMatrix(this.data.map(function (d) { d ? false : true }), this.width);
    }
}

exports.Matrix2bitMatrix = function (Matrix) {

}



//exports.getPointAtMatrix